<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
        stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M6.81836 6.75C6.99469 6.24875 7.34272 5.82608 7.80083 5.55685C8.25893 5.28762 8.79753 5.1892 9.32124 5.27903C9.84495 5.36886 10.32 5.64114 10.6622 6.04765C11.0044 6.45415 11.1917 6.96864 11.1909 7.5C11.1909 9 8.94086 9.75 8.94086 9.75"
        stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 12.75H9.0075" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'QuestionIcon'
}
</script>
